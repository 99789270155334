<template>
	<ion-app>
		<router-view :key="$route.fullPath" />
	</ion-app>
</template>

<script>
import { IonApp } from '@ionic/vue'

export default {
	components: { IonApp }
}
</script>
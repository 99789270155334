export default {
	namespaced: true,
	state: JSON.parse(localStorage.getItem('wzo_vue_hr_analysis')) || {
		analysis: null,
		keywords: null
	},
	mutations: {
		clearState(state) {
			state.analysis = null
			state.keywords = null
		},

		saveAnalysis(state) {
			localStorage.setItem('wzo_vue_hr_analysis', JSON.stringify(state))
		},

		setKeywords(state, value) {
			state.keywords = value
		},
		setAnalysis(state, value) {
			state.analysis = value
		}
	},
	actions: {
		handleAction({ commit }, { mutationName, value }) {
			commit(mutationName, value)
		}
	}
}

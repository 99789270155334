export default {
	namespaced: true,
	state: {
		questions: JSON.parse(localStorage.getItem('wzo_vue_hr_questions')) || []
	},
	getters: {
		getQuestions(state) {
			return state.questions
		},

		isQuestionFilled(state) {
			let result = false

			const filteredQuestions = state.questions.filter((question) => !question.open_question)

			filteredQuestions.forEach((question) => {
				let isQuestion = true
				let option = true
				let answer = true
				
				if (!question.question) isQuestion = false
				if (question.options) {
					question.options.forEach((option, index) => {
						if (question.type === 'two' && index === 2) return
						if (!option.text) option = false
						if (!option.option) answer = false
					})
				}

				if (isQuestion && option && answer) return result = true
			})

			return result
		},

		sortedQuestions(state) {
			const dupQuestions = JSON.parse(JSON.stringify(state.questions))
			dupQuestions.forEach((question, index) => {
				question.sort_order = index
				if (question.type === 'two') question.options.splice(2, 1)
				if (question.type === 'text') delete question.options
				if (question.options?.length) {
					question.options.forEach((option) => {
						if (option.option === 'Vyhovujúci') option.option = 'suitable'
						if (option.option === 'Akceptovateľný') option.option = 'acceptable'
						if (option.option === 'Nevyhovujúci') option.option = 'unsatisfying'
					})
				}
			})
			return dupQuestions
		},

		validAnswers(state) {
			let isValid = true
			const questions = JSON.parse(JSON.stringify(state.questions))
			questions.forEach((question) => {
				if (question.open_question) return
				if (question.type === 'two') question.options.pop()
				if (question.options.every(option => option.option === 'Nevyhovujúci' || option.option === '')) return isValid = false
			})
			return isValid
		},

		firstNotOpen(state) {
			return state.questions[0].open_question ? false : true
		}
	},

	mutations: {
		clearState(state) {
			state.questions = []
		},

		saveQuestions(state) {
			localStorage.setItem('wzo_vue_hr_questions', JSON.stringify(state.questions))
		},

		updateQuestions(state, value) {
			state.questions = value
		},

		updateBeQuestions(state, value) {
			const edited = JSON.parse(JSON.stringify(value))
			let lastId = edited.at(-1).id

			edited.forEach((question) => {
				lastId++
				question.id = lastId
				
				question.options?.length === 2 ? question.type = 'two' : question.options?.length === 3 ? question.type = 'three' : question.type = 'text'
				if (!question.options?.length) question.options = [
					{ text: '', option: '' },
					{ text: '', option: '' },
					{ text: '', option: '' }
				]
				if (question.options?.length === 2) question.options.push({
					text: '',
					option: ''
				})
				if (question.options) {
					question.options.forEach((option) => {
						if (option.option === 'suitable') option.option = 'Vyhovujúci'
						if (option.option === 'acceptable') option.option = 'Akceptovateľný'
						if (option.option === 'unsatisfying') option.option = 'Nevyhovujúci'
					})
				}
			})
			state.questions = edited
		},
		
		addQuestionWithOptions(state) {
			state.questions.push({
				id: state.questions.at(-1).id + 1,
				type: 'two',
				open_question: false,
				question: '',
				options: [
					{
						text: '',
						option: ''
					},
					{
						text: '',
						option: ''
					},
					{
						text: '',
						option: ''
					}
				]
			})
		},

		addGeneratedQuestions(state, questions) {
			const edited = JSON.parse(JSON.stringify(questions))
			const originalQuestions = JSON.parse(JSON.stringify(state.questions))

			state.questions = []

			let lastId = originalQuestions.at(-1).id

			edited.forEach((question) => {
				lastId++

				question.id = lastId + 1
				question.open_question ? question.type = 'text' : question.options?.length < 3 ? question.type = 'two' : question.type = 'three'
				if (!question.options?.length) question.options = [
					{ text: '', option: '' },
					{ text: '', option: '' },
					{ text: '', option: '' }
				]
				if (question.options?.length === 2) question.options.push({
					text: '',
					option: ''
				})
				if (question.options) {
					question.options.forEach((option) => {
						if (option.option === 'suitable') option.option = 'Vyhovujúci'
						if (option.option === 'acceptable') option.option = 'Akceptovateľný'
						if (option.option === 'unsatisfying') option.option = 'Nevyhovujúci'
					})
				}
			})

			const filtered = originalQuestions.filter(question => {
				if (question.open_question) {
					return question.question.trim() !== '';
				} else {
					const optionsNotEmpty = question.options.some(option => option.option.trim() !== '' || option.text.trim() !== '');
					console.log('Options not empty', question.question.trim() !== '' || optionsNotEmpty)
					return question.question.trim() !== '' || optionsNotEmpty;
				}
			})

			state.questions = [ ...filtered, ...edited ]
		},

		duplicateQuestion(state, questionId) {
			const duplicate = JSON.parse(JSON.stringify(state.questions))
			state.questions.splice(questionId + 1, 0, duplicate[questionId])
		},

		removeQuestion(state, questionId) {
			state.questions.splice(questionId, 1)
		},

		setQuestionTitle(state, { newValue, questionId }) {
			state.questions[questionId].question = newValue
		},

		addOption(state, questionId) {
			if (state.questions[questionId].options.length === 2) {
				state.questions[questionId].options.push({
					text: '',
					option: ''
				})
			}
		},

		// setTwoOptions(state, questionId) {
		// 	if (state.questions[questionId].options.length === 3) state.questions[questionId].options.splice(2, 1)
		// },

		isOpen(state, { questionId, isOpen } ) {
			state.questions[questionId].open_question = isOpen
		},

		setOptionValue(state, { newValue, questionId, optionId }) {
			state.questions[questionId].options[optionId].text = newValue
		},

		setOptionAnswer(state, { newValue, questionId, optionId }) {
			state.questions[questionId].options[optionId].option = newValue
		},
		
		questionType(state, { questionId, type }) {
			state.questions[questionId].type = type
		}
	},

	actions: {
		handleAction({ commit }, { mutationName, value }) {
			commit(mutationName, value)
		}
	}
}

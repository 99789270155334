import { App } 		from 'vue'
import { Options } 	from './types'

//Components

import ConfirmationService 	from 'primevue/confirmationservice'
import PrimeVue 			from 'primevue/config'
import Accordion 			from 'primevue/accordion'
import AccordionTab 		from 'primevue/accordiontab'
import InputText 			from 'primevue/inputtext'
import Password 			from 'primevue/password'
import DataTable 			from 'primevue/datatable'
import Column 				from 'primevue/column'
import ColumnGroup 			from 'primevue/columngroup'
import Row 					from 'primevue/row'
import Steps 				from 'primevue/steps'
import Dialog 				from 'primevue/dialog'
import Textarea 			from 'primevue/textarea'
import Button 				from 'primevue/button'
import SplitButton 			from 'primevue/splitbutton'
import InputSwitch 			from 'primevue/inputswitch'
import Dropdown 			from 'primevue/dropdown'
import ConfirmPopup 		from 'primevue/confirmpopup'
import Menu 				from 'primevue/menu'
import Divider 				from 'primevue/divider'
import SelectButton 		from 'primevue/selectbutton'
import FileUpload 			from 'primevue/fileupload'
import Image 				from 'primevue/image'
import Chips 				from 'primevue/chips'
import ConfirmDialog from 'primevue/confirmdialog'
import ProgressBar from 'primevue/progressbar';
import Tooltip from 'primevue/tooltip'
import OverlayPanel from 'primevue/overlaypanel'
import TieredMenu from 'primevue/tieredmenu'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

export default {
	install: (app: App, options: Options) => {
		if (options && options.disabled === true) return

		app.use(PrimeVue)
		app.use(ConfirmationService)
		app.component('PAccordion', Accordion)
		app.component('PAccordionTab', AccordionTab)
		app.component('PInputText', InputText)
		app.component('PPassword', Password)
		app.component('PDataTable', DataTable)
		app.component('PColumn', Column)
		app.component('PColumnGroup', ColumnGroup)
		app.component('PRow', Row)
		app.component('PSteps', Steps)
		app.component('PDialog', Dialog)
		app.component('PTextarea', Textarea)
		app.component('PButton', Button)
		app.component('PSplitButton', SplitButton)
		app.component('PInputSwitch', InputSwitch)
		app.component('PDropdown', Dropdown)
		app.component('PConfirmPopup', ConfirmPopup)
		app.component('PMenu', Menu)
		app.component('PDivider', Divider)
		app.component('PSelectButton', SelectButton)
		app.component('PFileUpload', FileUpload)
		app.component('PImage', Image)
		app.component('PChips', Chips)
		app.component('PConfirmDialog', ConfirmDialog)
		app.component('PProgressBar', ProgressBar)
		app.component('POverlayPanel', OverlayPanel)
		app.component('PTieredMenu', TieredMenu)
		app.directive('Tooltip', Tooltip)
	}
}
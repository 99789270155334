export default {
	namespaced: true,
	state: {
		title: '',
		description: '',
		companyName: '',
		processSteps: [
			{
				name: 'cpnw.step.preselection'
			},
			{
				name: 'cpnw.step.telephone'
			},
			{
				name: 'cpnw.step.job-interview'
			},
			{
				name: 'cpnw.step.final-selection'
			}
		]
	},
	
	getters: {
		sortedSteps(state) {
			const dupSteps = JSON.parse(JSON.stringify(state.processSteps))
			dupSteps.forEach((step, index) => {
				step.sort_order = index
			})
			return dupSteps
		}
	},

	mutations: {
		clearState(state) {
			state.title = '',
			state.description = '',
			state.processSteps = [
				{
					name: 'cpnw.step.preselection'
				},
				{
					name: 'cpnw.step.telephone'
				},
				{
					name: 'cpnw.step.job-interview'
				},
				{
					name: 'cpnw.step.final-selection'
				}
			]
		},

		updateSteps(state, value) {
			state.processSteps = value
		},

		setTitle(state, value) {
			state.title = value
		},

		setDescription(state, value) {
			state.description = value
		},

		setCompanyName(state, value) {
			state.companyName = value
		},

		addStep(state) {
			state.processSteps.push({name: ''})
		},

		removeStep(state, id) {
			if (id > 0) state.processSteps.splice(id, 1)
		},

		setStep(state, { newValue, index }) {
			state.processSteps[index].name = newValue
		}
	},

	actions: {
		handleAction({ commit }, { mutationName, value }) {
			commit(mutationName, value)
		}
	}
}

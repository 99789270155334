/* eslint-disable */
import axios from 'axios'
import store from '@/plugins/app/_config/store'
import router from '@/plugins/app/_config/router'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error: any) {
	if (error?.response.status === 403) router.push({ name: 'Not approved' })

	if (!error.response || error.response.status != 401 || error.response.data.error === 'Token not provided' ||  error.response.data.error != 'Token has expired') {
		return Promise.reject(error)
	}
	
	if (!isRefreshing) {
		isRefreshing = true

		refreshHook = new Promise((resolve, reject) => {
			store.dispatch('auth/refreshToken')
				.then((token) => resolve(token))
				.catch((error) => reject(error))
				.finally(() => isRefreshing = false)
		})
	}

	try {
		const token = await refreshHook
		error.config.headers = {
			Authorization: `Bearer ${token}`,
		}

		return Promise.resolve(await axios.request(error.config))
	} catch (error) {
		return store.dispatch('auth/logout')
	}
}

axios.interceptors.response.use(
	(response) => response,
	(error) => _unauthorised(error),
)

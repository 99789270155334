export default {
	namespaced: true,
	state: JSON.parse(localStorage.getItem('wzo_vue_hr_we_will_require')) || {
		fullName: null,
		email: null,
		phoneNumber: null,
		biography: null,
		gdpr: null,
		lang: null
	},
	getters: {
		gdprError (state) {
			return state.gdpr === 'Áno' && (!state.gdprUrl && !state.gdprFile) ? true : false
		},

		getBiography(state) {
			return state.biography === 'Áno' ? true : false
		},
	},
	mutations: {
		clearState(state) {
			state.fullName = null
			state.email = null
			state.phoneNumber = null
			state.biography = null
			state.gdpr = null
		},

		saveState(state) {
			delete state.gdprFile
			localStorage.setItem('wzo_vue_hr_we_will_require', JSON.stringify(state))
		},

		setFullName(state, value) {
			state.fullName = value
		},

		setEmail(state, value) {
			state.email = value
		},

		setPhoneNumber(state, value) {
			state.phoneNumber = value
		},

		setBiography(state, value) {
			state.biography = value
		},

		setGdpr(state, value) {
			state.gdpr = value
			if (value == 'Nie') {
				delete state.gdprFile
				delete state.gdprUrl
			}
		},

		setFile(state, file) {
			state.gdprFile = file
			delete state.gdprUrl
		},

		setUrl(state, value) {
			state.gdprUrl = value
			delete state.gdprFile
		},

		removeFile(state) {
			delete state.gdprFile
		}
	},
	actions: {
		handleAction({ commit }, { mutationName, value }) {
			commit(mutationName, value)
		}
	}
}
